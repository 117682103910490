<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <div class="background">
    <div class="head">
      <img src="../../images/ProjectCenterHead.jpg" alt="">
    </div>
    <div style="padding-left: 3.6rem;">
      <div>
        <div class="dropdownTitle">
          <div @click="dropdownFocus(1)">
            领域
            <i style="font-size:0.24rem; padding-left: 0.35rem" class="fa">&#xf107</i>
          </div>
          <div @click="dropdownFocus(2)">
            发布时间
            <i style="font-size:0.24rem; padding-left: 0.25rem" class="fa">&#xf107</i>
          </div>
          <div @click="dropdownFocus(3)">
            所处阶段
            <i style="font-size:0.24rem; padding-left: 0.25rem" class="fa">&#xf107</i>
          </div>
          <div @click="dropdownFocus(4)">
            交易模式
            <i style="font-size:0.24rem; padding-left: 0.25rem" class="fa">&#xf107</i>
          </div>
        </div>
        <!--领域-->
        <div class="dropdownListField" v-show="this.focusDropdown === 1" @mouseleave="dropdownFocus(0)">
          <!--产品设计-->
          <div class="dropdownListTitle" @click="dropdownFieldFocus(1)">
            产品设计
            <i style="font-size:0.24rem; padding-left: 0.35rem" class="fa">&#xf107</i>
          </div>
          <div v-show="this.focusField === 1">
            <el-checkbox-group v-model="checkField" @change="getInfo">
              <el-space direction="vertical" alignment="flex-start">
                <el-checkbox label="医疗设备" />
                <el-checkbox label="仪器设备" />
                <el-checkbox label="生活家电" />
                <el-checkbox label="消费电子" />
                <el-checkbox label="通信出行" />
              </el-space>
            </el-checkbox-group>
          </div>
          <!--研发工程-->
          <div class="dropdownListTitle" @click="dropdownFieldFocus(2)">
            研发工程
            <i style="font-size:0.24rem; padding-left: 0.35rem" class="fa">&#xf107</i>
          </div>
          <div v-show="this.focusField === 2">
            <el-checkbox-group v-model="checkField" @change="getInfo">
              <el-space direction="vertical" alignment="flex-start">
                <el-checkbox label="结构设计" />
                <el-checkbox label="自动化设计" />
              </el-space>
            </el-checkbox-group>
          </div>
          <!--传播设计内容-->
          <div class="dropdownListTitle" @click="dropdownFieldFocus(3)">
            传播设计
            <i style="font-size:0.24rem; padding-left: 0.35rem" class="fa">&#xf107</i>
          </div>
          <div v-show="this.focusField === 3">
            <el-checkbox-group v-model="checkField" @change="getInfo">
              <el-space direction="vertical" alignment="flex-start">
                <el-checkbox label="品牌vi" />
                <el-checkbox label="电商视觉" />
                <el-checkbox label="网站/APP" />
                <el-checkbox label="产品包装" />
                <el-checkbox label="Logo设计" />
                <el-checkbox label="画册设计" />
                <el-checkbox label="UI设计" />
              </el-space>
            </el-checkbox-group>
          </div>
          <!--空间设计内容-->
          <div class="dropdownListTitle" @click="dropdownFieldFocus(4)">
            空间设计
            <i style="font-size:0.24rem; padding-left: 0.35rem" class="fa">&#xf107</i>
          </div>
          <div v-show="this.focusField === 4">
            <el-checkbox-group v-model="checkField" @change="getInfo">
              <el-space direction="vertical" alignment="flex-start">
                <el-checkbox label="商业空间" />
                <el-checkbox label="厂房改造" />
                <el-checkbox label="会展陈列" />
                <el-checkbox label="空间导视" />
              </el-space>
            </el-checkbox-group>
          </div>
        </div>
        <!--发布时间-->
        <div class="dropdownListTime" v-show="this.focusDropdown === 2" @mouseleave="dropdownFocus(0)">
          <el-checkbox-group v-model="checkTime" :min="0" :max="1" @change="getInfo">
            <el-space direction="vertical" size="40" alignment="flex-start">
              <el-checkbox label="一周内" />
              <el-checkbox label="半月内" />
              <el-checkbox label="一月内" />
              <el-checkbox label="半年内" />
              <el-checkbox label="一年内" />
            </el-space>
          </el-checkbox-group>
        </div>
        <!--所处阶段-->
        <div class="dropdownListStage" v-show="this.focusDropdown === 3" @mouseleave="dropdownFocus(0)">
          <el-checkbox-group v-model="checkStage" :min="0" :max="1" @change="getInfo">
            <el-space direction="vertical" size="40" alignment="flex-start">
              <el-checkbox label="正在招募的项目" />
              <el-checkbox label="服务中的项目" />
              <el-checkbox label="已完成的项目" />
              <el-checkbox label="全部项目" />
            </el-space>
          </el-checkbox-group>
        </div>
        <!--交易模式-->
        <div class="dropdownListMode" v-show="this.focusDropdown === 4" @mouseleave="dropdownFocus(0)">
          <el-checkbox-group v-model="checkMode" :min="0" :max="1" @change="getInfo">
            <el-space direction="vertical" size="40" alignment="flex-start">
              <el-checkbox label="自由交易" />
              <el-checkbox label="协同开发" />
              <el-checkbox label="悬赏项目" />
              <el-checkbox label="政企合作" />
            </el-space>
          </el-checkbox-group>
        </div>
      </div>
      <!--以下为循环渲染的筛选结果-->
      <div class="table">
        <el-scrollbar max-height="2450px">
          <div class="tableColumn" v-for="(item,i) in projects">
            <div class="pic">
              <img :src="item.pic" alt="">
            </div>
            <div class="top">
              <div class="data">
                <div class="dataDay">
                  {{item.day}}
                </div>
                <div class="dataMonth">
                  {{item.yearAndMonth}}
                </div>
              </div>
              <div class="name">
                {{item.projectName}}
              </div>
              <div :style="item.stageStyle" class="recruit">
                {{ item.stage }}
              </div>
            </div>
            <div class="bottom">
              <div class="num">
                <p>招募人数</p>
                <p>{{item.needNum}}</p>
              </div>
              <div class="deadLine">
                <p>剩余时间</p>
                <p>{{item.remainingTime}}</p>
              </div>
              <div class="budget">
                <p>项目预算</p>
                <p>{{item.budget}}</p>
              </div>
              <button @click="this.$router.push({path:'/Introduce',query: {id: item.id}})">查看详情</button>
            </div>
          </div>
        </el-scrollbar>
        <div style="height: 140px;padding-top: 44px">
          <el-pagination
              background
              layout="prev, pager, next"
              v-model:page-size="submitForm.pageSize"
              v-model:total="submitForm.total"
              v-model:current-page="submitForm.currentPage"
              style=";--el-pagination-hover-color: #ffffff;"
              @change="getInfo" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectHome",
  data() {
    return {
      checkField:[],
      checkTime:[],
      checkStage:[],
      checkMode:[],


      submitForm:{
        total:0,
        currentPage:1,
        pageSize: 7,

        type2:[''],
        timeType:'一月内',
        stage:'全部',
        transactionMode:'',

      },

      //状态样式控制
      projects: {
        stageStyle:'',
      },

      //下拉菜单显示控制
      focusDropdown: 0,
      //领域细分菜单显示控制
      focusField: 0,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
    this.getInfo();
  },
  methods:{
    dropdownFocus(index){
      if (this.focusDropdown === index){
        this.focusDropdown = 0;
      }else {
        this.focusDropdown = index;
      }
    },
    dropdownFieldFocus(index){
      if (this.focusField === index){
        this.focusField = 0;
      }else {
        this.focusField = index;
      }
    },
    getInfo(){
      if (this.$route.query.ProjectStage !== undefined){
        this.submitForm.stage = this.$route.query.ProjectStage;
      }

      if (this.checkField.length > 0){
        this.submitForm.type2 = this.checkField;
      }else {
        this.submitForm.type2 = [''];
      }

      if (this.checkTime.length > 0){
        this.submitForm.timeType = this.checkTime[0];
      }else {
        this.submitForm.timeType = '一年内';
      }

      if (this.checkStage.length > 0){
        this.submitForm.stage = this.checkStage[0];
      }else {
        this.submitForm.stage = '全部';
      }

      if (this.checkMode.length > 0){
        this.submitForm.transactionMode = this.checkMode[0];
      }else {
        this.submitForm.transactionMode = '';
      }
      this.$http({
        method: 'post',
        url: '/showProjects',
        data: this.submitForm
      }).then(res => {
        this.projects = res.data.projects;
        this.submitForm.total = res.data.count;

        for(let i=0;i<this.projects.length;i++){
          if (this.projects[i].stage === 1){
            this.projects[i].stage = "报名中";
          }else if(this.projects[i].stage === 2){
            this.projects[i].stage = "服务中";
          }
          else if(this.projects[i].stage === 3){
            this.projects[i].stage = "即将完成";
          }else if(this.projects[i].stage === 4){
            this.projects[i].stage = "准备中";
          }
          else if(this.projects[i].stage === 5){
            this.projects[i].stage = "已完成";
            this.projects[i].stageStyle = "background-color: #9e9e9e;";
          }
        }
      })
    },
    scroll() {
          this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
    }

  },
}
</script>

<style scoped>

.background{
  position: relative;
  width: 1920px;
  background: rgb(251,249,248);
  /* padding-top: 100px; */
  padding-bottom: 100px;
}

.head{
  width: 100%;
  height: 1080px;
  margin-bottom: 100px;
}

.head img{
  width: 100%;
  height: 100%;
}

.dropdownTitle{
  width: 1560px;
  height: 96px;
}

.dropdownTitle div{
  margin-bottom: 30px;
  float: left;
  height: 65px;
  color: #212121;
  margin-right: 30px;
  line-height: 65px;
  /*border-radius: 5px;*/
  background-color: #ffffff;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  border-radius: 5px;
  font-size: 21px;
  font-weight: normal;
  padding-left: 0.35rem;
  cursor: pointer;
}

.dropdownTitle div:hover {
  background-color: #ff6460;
  color: white;
}

.dropdownTitle div:nth-child(1){
  width: 155px;
}

.dropdownTitle div:nth-child(2){
  width: 180px;
}

.dropdownTitle div:nth-child(3){
  width: 180px;
}

.dropdownTitle div:nth-child(4){
  width: 180px;
}

/*虽然是灰色的但其实是生效的*/
.el-checkbox{
  --el-checkbox-checked-font-color: #ff6460;
  --el-checkbox-checked-input-border-color:  #ff6460;
  --el-checkbox-checked-background-color: #ff6460;
  --el-checkbox-input-border-color-hover: #ff6460;
}

.dropdownListField{
  position: absolute;
  margin-top: -15px;
  padding-left: 40px;
  float: left;
  width: 210px;
  height: auto;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  border-radius: 5px;
  background-color: white;
  z-index: 999;
}

.dropdownListTitle{
  height: 46px;
  padding-top: 27px;
  margin-bottom: 26px;
  font-size: 18px;
  font-weight: normal;
  /*横线*/
  border-width: 0 1px;
  color: #0f0f0f;
  cursor: pointer;

}

.dropdownListTime{
  position: absolute;
  margin-top: -15px;
  margin-left: 185px;
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
  width: 180px;
  height: auto;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  border-radius: 5px;
  background-color: white;
  z-index: 999;
}

.dropdownListStage{
  position: absolute;
  margin-top: -15px;
  margin-left: 395px;
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
  width: 180px;
  height: auto;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  border-radius: 5px;
  background-color: white;
  z-index: 999;
}

.dropdownListMode{
  position: absolute;
  margin-top: -15px;
  margin-left: 605px;
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
  width: 180px;
  height: auto;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  border-radius: 5px;
  background-color: white;
  z-index: 999;
}

.table{
  width: 1200px;
  height: auto;
  padding-top: 36px;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  border-radius: 5px;
  background-color: white;
  overflow-x: hidden;
  overflow-y: hidden;
}

.tableColumn{
  width: 1120px;
  height: 366px;
  background-color: #ffffff;
  margin: 0 auto;
  border-bottom: 2px solid #faf0ee;
  padding-top: 44px;

}

.tableColumn .top{
  position: relative;
  float: left;
  width: 636px;
  height: 96px;
}

.tableColumn .pic{
  float: left;
  width: 458px;
  height: 284px;
  background-color: #ec414d;
  margin-left: 0.24rem;
  cursor: pointer;
}

.tableColumn .pic img{
  width: 458px;
  height: 284px;
  transition: all 1s;
}

.tableColumn .pic img:hover{
  width: 105%;
  height: 105%;
}

.tableColumn .data{
  float: left;
  width: 96px;
  height: 96px;
  margin-left: 58px;
  text-align: center;
  line-height: 10.25rem;
  color: #ec414d;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0,0,0,.2);
  padding-top: 0.24rem;
}

.tableColumn .dataDay{
  height: 0.24rem;
  font-size: 0.24rem;
  font-weight: 700;
  line-height: 0.24rem;
  color: #ec414d;
  margin-bottom: 15px;
}

.tableColumn .dataMonth{
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  color: #ec414d;
}

.tableColumn .name{
  float: left;
  height: 0.24rem;
  font-size: 0.24rem;
  line-height: 0.24rem;
  color: #212121;
  font-weight: 500;
  margin-left: 0.24rem;
  margin-top: 32px;
}

.tableColumn .recruit{
  position: absolute;
  float: left;
  right: 40px;
  width: 70px;
  height: 26px;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  background-color: #ff6460;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 30px;
}

.tableColumn .bottom{
  float: left;
  width: 636px;
  height: 192px;
  margin-top:30px;
  padding-left: 180px;
}

.tableColumn .num{
  float: left;
  height: 75px;
  text-align: center;
}

.tableColumn .num p:nth-child(1){
  font-size: 16px;
  color: #9e9e9e;
  margin-bottom: 20px;
}

.tableColumn .num p:nth-child(2){
  font-size: 18px;
  color: #212121;
}

.tableColumn .deadLine{
  float: left;
  height: 75px;
  margin-left: 80px;
  text-align: center;
  margin-bottom: 38px;
}

.tableColumn .deadLine p:nth-child(1){
  font-size: 16px;
  color: #9e9e9e;
  margin-bottom: 20px;
}

.tableColumn .deadLine p:nth-child(2){
  font-size: 18px;
  color: #212121;
}

.tableColumn .budget{
  float: left;
  height: 75px;
  margin-left: 86px;
  text-align: center;
}

.tableColumn .budget p:nth-child(1){
  font-size: 16px;
  color: #9e9e9e;
  margin-bottom: 20px;
}

.tableColumn .budget p:nth-child(2){
  font-size: 18px;
  color: #212121;
}

.tableColumn button{
  width: 150px;
  height: 54px;
  color: #fef6f6;
  line-height: 54px;
  background-color: #212121;
  border-radius: 3px;
  font-size: 18px;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#ec414d 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all .7s;
  margin-left: 285px;
}

.tableColumn button:hover{
  background-size: 300%;
}

/*分页器样式部分start*/
::v-deep .el-pagination{
  padding-right: 30px;
  text-align: right;
}

::v-deep .el-pagination .btn-next{
  border-radius: 50%;
  padding: 9px;
}

::v-deep .el-pagination .btn-prev{
  border-radius: 50%;
  /*color: #ffffff;*/
  padding: 9px;
}

::v-deep .el-pager .active{
  background-color: #ec414d!important;
}

::v-deep .el-pager .number{

  border-radius: 50%!important;
  color: #212121;
}

::v-deep .el-pager .number:hover{
  background-color: #ec414d;
  color: #ffffff;
}


/*分页器样式部分end*/
</style>